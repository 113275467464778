import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Route, Switch, Redirect } from "react-router"
import { BrowserRouter } from "react-router-dom"
import { createStore } from "redux"
import { Provider } from "react-redux"
import { uuid } from "uuidv4"


import { MuiThemeProvider } from "@material-ui/core/styles"
import { SuspenseScreen } from "./shared/components/Suspense"
import { MaterialFoxTheme } from "./shared/themes/reserveraccoon"
import { serviceWorkerSuccess, serviceWorkerHasUpdate, serviceWorkerInitialize, serviceWorkerUpdateTimeout } from "./redux/actions"
import reducer from "./redux/reducers"
import { register as registerServiceWorker } from "./serviceWorkerRegistration"
import reportWebVitals from './reportWebVitals';
import AppFrameContainer from "./AppFrame"

const WelcomeContainer = lazy(() => import("./Welcome"))
const SensorListContainer = lazy(() => import("./SensorList"))
const AdminsContainer = lazy(() => import("./Admins"))
const MerchantAccountContainer = lazy(() => import("./Merchants"))
const HudContainer = lazy(() => import("./Hud"))
const KioskContainer = lazy(() => import("./Kiosk"))
const AirportConfigContainer = lazy(() => import("./AirportConfig"))

const store = createStore(reducer)


// import {
//   ApolloClient,
//   InMemoryCache
// } from "@apollo/client";
// const client = new ApolloClient({
//   uri: process.env.REACT_APP_GRAPHQL_HOST,
//   cache: new InMemoryCache({
//     addTypename: false,
//   }),
//   headers: {
//     authorization: generateBasicAuth(process.env.REACT_APP_ADMIN_USER, process.env.REACT_APP_ADMIN_SECRET)
//   }
// });


function authorizedRender(component: React.ReactElement) {
    const state = store.getState()
    if (state.credentials.userId  && state.credentials.accessToken) {
        return <AppFrameContainer component={component} />
    }
    else {
        return <Redirect to="/" />
    }
}


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <MuiThemeProvider theme={MaterialFoxTheme}>
                <BrowserRouter>
                    <Suspense fallback={<SuspenseScreen />}>
                        <Switch>
                            <Route exact path="/" render={(props: any) => (<AppFrameContainer component={  <WelcomeContainer {...props} /> } key={uuid()} />  )} />
                            <Route exact path="/hud/:netId?" render={(props: any) => (<AppFrameContainer component={  <HudContainer {...props} /> } />)} />
                            <Route exact path="/kiosk" render={(props: any) => (<AppFrameContainer component={  <KioskContainer {...props} /> } />)} />
                            <Route exact path="/sensors" render={(props: any) => authorizedRender(<SensorListContainer {...props} />)} />
                            <Route exact path="/admins" render={(props: any) => authorizedRender(<AdminsContainer {...props} />)} />
                            <Route exact path="/merchants" render={(props: any) => authorizedRender(<MerchantAccountContainer {...props} />)} />
                            <Route exact path="/airports" render={(props: any) => authorizedRender(<AirportConfigContainer {...props} />)} />
                        </Switch>
                    </Suspense>
                </BrowserRouter>
            </MuiThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
store.dispatch(serviceWorkerInitialize())
registerServiceWorker({
    onSuccess: () => {
        store.dispatch(serviceWorkerSuccess())
    },
    onUpdate: (registration) => {
        store.dispatch(serviceWorkerHasUpdate(registration))
    }
});

window.setTimeout(() => store.dispatch(serviceWorkerUpdateTimeout()), 10000)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

