import { SessionAction, SessionState, ReduxAction} from "../base"

export function setSessionAirportId(airportId: string, airportName: string): ReduxAction<SessionAction, SessionState> {
    const action = {
        type: SessionAction.SET_AIRPORT,
        props: {
            airportId,
            airportName
        }
    }
    return action
}

export function clearSession(): ReduxAction<SessionAction, SessionState> {
    const action = {
        type: SessionAction.CLEAR,
        props: {}
    }
    return action
}